<template>
  <div id="app">
    <Index @preventivi="Preventivi"/>
    <PreventiviHome v-if="showPreventivi"></PreventiviHome>
  </div>
</template>

<script>
import Index from './components/Index.vue'
import PreventiviHome from './components/PreventiviHome.vue'

export default {
  name: 'App',
  components: {
    Index,
    PreventiviHome
  },
  data() {
    return {
      showPreventivi: false
    }
  },
  methods:
  {
    Preventivi()
    {
      this.showPreventivi=true;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
