<template>
    <div style="margin-left: 2rem; margin-right: 2rem; margin-top: 2rem">
        <h5 class="footer">Intestazione:</h5>
        <br>
        <div v-for="(el, index) in intestazioneArray" :key="index">
            <h5 style="text-align: left" v-text="el"></h5>
        </div>
        <template>
            <div v-for="(el, index) in element" :key="index">
                <b-form-textarea
                :id="'textarea'+index"
                v-model="el.articolo"
                placeholder="Articolo"
                rows="3"
                max-rows="6"
                style="margin-top:1rem"
                ></b-form-textarea>
                <b-form-input v-model="el.costo" style="width: 10rem; margin-top:1rem" type="number" placeholder="Costo"></b-form-input>
            </div>
            <b-button class="btn" variant="success" style ="margin-right: 0.5rem; margin-left: 1rem" v-on:click="addField">Nuovo articolo</b-button>
            <b-button class="btn" variant="success" style ="margin-right: 0.5rem; margin-left: 0.5rem" v-on:click="removeField">Rimuovi ultimo articolo</b-button>
            <b-button class="btn" variant="success" style ="margin-right: 0.5rem; margin-left: 0.5rem" v-on:click="generate">Genera Anteprima</b-button>
            <b-button class="btn" variant="success" style ="margin-right: 1rem; margin-left: 0.5rem" v-on:click="download">Scarica e stampa</b-button>
        </template>
        <h5 class="footer" style="margin-top: 1rem">Totale: {{compTotale}} + IVA come per legge</h5>
        <h5>Anteprima generata:</h5> 
        <div v-html="preview"></div>
    </div>
</template>

<script>
    import jsPDF from 'jspdf'

    export default 
    {
        data()
        {
            return {
                // font-size: 17px; 
                preview:null,
                totale: 0,
                intestazione: '<p style="text-align: left; margin-top: 2rem">Zizza Giuseppe Infissi<br>Via delle comunicazioni 2<br>72017, Ostuni(BR)<br>P.Iva: 01758480741<br>Cellulare: 3393838949, Telefono: 0831-304581<hr style="  border-top: 3px solid #000000; border-radius: 5px;"></p>',
                element: [{articolo: "", costo: 0}],
                intestazioneArray: ["  Zizza Giuseppe Infissi", "  Via delle comunicazioni 2", "  72017, Ostuni(BR)", "  P.Iva: 01758480741", "  Cellulare: 3393838949, Telefono: 0831-304581"],
                bodyArray: [],
            }
        },
        computed:
        {
            compTotale()
            {
                let i=0;

                this.element.forEach(el =>
                {
                    i = i + parseInt(el.costo);
                })

                return i;
            }
        },
        methods: {
            addField()
            {
                this.element.push({articolo: "", costo: 0})
            },
            removeField()
            {
                this.element.pop()
            },
            generate()
            {
                let final = "<div style ='margin-left: 2rem; margin-right: 2rem;'>";

                final = final + this.intestazione;

                this.element.forEach(el =>
                {
                    final = final + '<p style="text-align: left; font-size: 17px;">'+el.articolo+'</p><br><p style="text-align: left;    font-size: 17px;">Costo: '+el.costo+' €</p><br>'
                })

                final = final + '<p style="text-align: left;     font-size: 17px;">Totale Preventivo: '+this.compTotale+'€ + IVA come per legge</p>'

                this.preview = final +"</div>";

                return final +"</div>";
            },
            download()
            {
                this.generate();

                this.bodyArray=[];
                
                this.intestazioneArray.forEach(el =>
                {
                    this.bodyArray.push(el)
                })

                const doc = new jsPDF('p', 'pt', 'a4', true);
                doc.setFontSize(11)
                let width = doc.internal.pageSize.getWidth();
                let height = doc.internal.pageSize.getHeight();

                

                console.log(width, height)
                

                this.element.forEach( el =>
                {
                    let elements = doc.splitTextToSize(el.articolo, width-50)
                    
                    this.bodyArray.push("")

                    elements.forEach(art => {
                        this.bodyArray.push(art);
                    })
                    this.bodyArray.push("")
                    this.bodyArray.push("Costo: "+el.costo+" €")
                    this.bodyArray.push("")
                })
                
                this.bodyArray.push("Totale Preventivo: "+this.compTotale+" € + IVA come per legge")

                console.log(this.bodyArray)


                doc.roundedRect(25, 15, 250, 85, 3, 3, 'S')

                var y = 30;
                this.bodyArray.forEach( el => {                
                    if (y > height-30) {
                        y = 30;
                        doc.addPage();
                    }
                    doc.text(25, y, el);
                    y = y + 15;
                })
                doc.save('preventivo.pdf');               
                
            }
        }
    }
</script>
<style>
.footer
{
    text-align: left;
}

hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

</style>