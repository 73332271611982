import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap'
import VueRouter from 'vue-router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueRouter);

Vue.use(BootstrapVue);

const routes = [
{
  path: "/"
}
];

const router = new VueRouter({
  //mode: 'history',
  routes})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
